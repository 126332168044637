import './App.css';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ClipLoader from "react-spinners/ClipLoader";

const Loading =
    <div>
        <Container className="App-header">
            <Row className="justify-content-center text-title">
                {/* <h2></h2> */}
                <ClipLoader></ClipLoader>
            </Row>
            <Row className="text-space">
                <h4>Fetching record</h4>
            </Row>
        </Container>
    </div>

export default Loading;

