import swal from 'sweetalert';
import ReactDOM from 'react-dom';
import Thanks  from './Thanks';
import { ajax } from 'jquery';
import {isMock, isProduction} from './App'

// _host = isMock ? 'http://localhost' : 'https://fb.pmsum.ga/';
// _base = 'clear-tape-307908/us-central1/';
// url = https://fb.pmsum.ga/clear-tape-307908/us-central1/xxx

export function acceptOrReject(status, clinicData, remark){
    var title = "";
    if (status == 2)
        title = "Invitation Accepted";
    else if (status == 3)
        title = "Invitation Declined";
    // console.log(status);
    // console.log(clinicData);
    // console.log(remark);

    if (remark == null || remark == undefined)
        remark = "";
    
    let apiUrl = "";
    if (isProduction)
        apiUrl = "https://asia-southeast2-clear-tape-307908.cloudfunctions.net/invitationUpdateClinic";
    else{
        if (isMock)
            apiUrl = "http://localhost:5001/clear-tape-307908/us-central1/invitationUpdateClinic";
        else
            apiUrl = "https://fb.pmsum.ga/clear-tape-307908/us-central1/invitationUpdateClinic"; 
    }
   
    ajax({
        method: 'post',
        url: apiUrl,
        // url: 'https://fb.pmsum.ga/clear-tape-307908/us-central1/invitationUpdateClinic',
        data: {
            id: clinicData.id,
            pSid: clinicData.pSid,
            sid: clinicData.sid,
            status: status,
            appointmentTime: 0,
            remark: remark
        },
        complete:function(data){
            if (data.status == 201){
            swal({
                title: title,
                icon: "success",
            });
            }
            else{
                swal({
                    title: "Something went wrong!",
                    icon: "error",
                });        
            }

            ReactDOM.render(
                Thanks,
                document.getElementById('root')
            );
        }
    });
}

export function suggestTime(dateTimeInput, remark, clinicData) {
    console.log(dateTimeInput);
    var dateTimeSecond= new Date(dateTimeInput);
    if (dateTimeSecond == "Invalid Date" ){
        alert("Invalid Date");
        return;
    }
    dateTimeSecond = Date.parse(dateTimeSecond) / 1000;
    
    // console.log(clinicData);
    // console.log(dateTimeSecond);
    let apiUrl = "";
    if (isProduction)
        apiUrl = "https://asia-southeast2-clear-tape-307908.cloudfunctions.net/invitationUpdateClinic";
    else{
        if (isMock)
            apiUrl = "http://localhost:5001/clear-tape-307908/us-central1/invitationUpdateClinic";
        else
            apiUrl = "https://fb.pmsum.ga/clear-tape-307908/us-central1/invitationUpdateClinic";  
    }
   
    ajax({
        method: 'post',
        url: apiUrl,
        //url: 'https://fb.pmsum.ga/clear-tape-307908/us-central1/invitationUpdateClinic',
        data: {
            id: clinicData.id,
            pSid: clinicData.pSid,
            sid: clinicData.sid,
            status: 4,
            appointmentTime: dateTimeSecond,
            remark: remark
        },
        complete:function(data){
            if (data.status == 201){
                swal({
                    title: "Request Submitted!",
                    icon: "success",
                });
            }
            else{
                swal({
                    title: "Something went wrong!",
                    icon: "error",
                });        
            }

            ReactDOM.render(
                Thanks,
                document.getElementById('root')
            );
        }
    });
}
