import './App.css';
import React from 'react';
import { Container, Row, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppBar from './AppBar'

const Thanks =
    <div>
        <AppBar />
        <Container>
            <div>
                <Row className="justify-content-center text-title">
                    <h1 className="display-3">
                        Thank you!
                </h1>
                </Row>

                <Row className="justify-content-center text-space">
                    <h3>
                        You had submitted your response.
                </h3>
                </Row>
                <hr></hr>
                <Row className="justify-content-center text-space">
                    <h5>
                    <strong>Please check your email</strong> for further notice.
                    </h5>
                </Row>

            </div>
    </Container>
    </div>

export default Thanks;
// with export default, {} is not needed  during import, vice versa etc..

