import logo from './icons/ms-icon-70x70.png'
import { Navbar, Card } from 'react-bootstrap';
import React, { Component } from 'react';
import './App.css';

class AppBar extends Component {
    render() {
        return (
            <Navbar bg="light" expand="lg">
                <Card className="card-title app-bar">
                    <img src={logo} alt="wadasdsadsa" />
                </Card>
                <Navbar.Brand>Proposal Management UM</Navbar.Brand>
            </Navbar>
        );
    }
}

export default AppBar;