import './App.css';
import React from 'react';
import { Modal, Button, InputGroup, FormControl, Container,Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as common from './common.js';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { BiComment } from "react-icons/bi";
import $ from "jquery";


function RejectModal(show, hideModal, clinicData) {
    // $('#datetimeInput').keypress(function(e) {
    //     e.preventDefault();
    // });
        return (
        <Modal size="lg" show={show} onHide={hideModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Reject Invitation</Modal.Title>
            </Modal.Header>

            <Container>
                <div className="modal-element">
                    <InputGroup className="mb-3" size="lg">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1"><BiComment /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl as="textarea"
                            id = "remarksInput"
                            placeholder="Do you mind telling us why?"
                            aria-label="Remarks"
                            onKeyUp = {checkRemaks}
                        />
                    </InputGroup>
                </div>

            </Container>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>
                    Close
          </Button>
                <Button id= "btnSubmit" variant="primary" onClick={() => { common.acceptOrReject(3, clinicData, $('#remarksInput').val());}}>
                    No and Continue
          </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RejectModal;

function checkRemaks(){
    if ($('#remarksInput').val() == "")
        $('#btnSubmit').text("No and Continue");
    else
        $('#btnSubmit').text("Save Changes");
    
}