import './App.css';
import React from 'react';
import { Modal, Button, InputGroup, FormControl, Container,Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as common from './common.js';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { BiComment } from "react-icons/bi";
import $ from "jquery";


function SuggestDateTimeModal(show, hideModal, clinicData) {
    // $('#datetimeInput').keypress(function(e) {
    //     e.preventDefault();
    // });
        return (
        <Modal size="lg" show={show} onHide={hideModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Propose New Time</Modal.Title>
            </Modal.Header>

            <Container>
                <div className="modal-element" >
                    <DateTimePickerComponent id="datetimeInput" cssClass="font-large" placeholder="Select a date and time"/>
                </div>

                <div className="modal-element">
                    <InputGroup className="mb-3" size="lg">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1"><BiComment /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl as="textarea"
                            id = "remarksInput"
                            placeholder="Remarks"
                            aria-label="Remarks"
                            className="inputlg"
                        />
                    </InputGroup>
                </div>

            </Container>

            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>
                    Close
          </Button>
                <Button variant="primary" onClick={() => { common.suggestTime(document.getElementById("datetimeInput").value,$('#remarksInput').val(), clinicData);}}>
                    Save Changes
          </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SuggestDateTimeModal;
