import './App.css';
import React, { Component } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as common from './common.js';
import queryString from 'query-string'
import AppBar from './AppBar'
import SuggestDateTimeModal from './SuggestDateTime'
import RejectModal from './Reject'
import { VscWarning } from "react-icons/vsc"
import axios from 'axios';
import Loading from './Loading';
import moment from 'moment'
import 'moment-timezone';


export var isMock = false;
export var isProduction = true;

class App extends Component {

  constructor(props) {
    super();
    this.data = { creatorName: "creator1", pTitle: "research title 1", date: "date", duration: "duration", isExpired: false };
    this.clinicData = null;
    this.state = {
      showSuggest: false,
      showReject: false,
      loading: true
    }

    this.showSuggestModal = this.showSuggestModal.bind(this);
    this.hideSuggestModal = this.hideSuggestModal.bind(this);
    this.showRejectModal = this.showRejectModal.bind(this);
    this.hideRejectModal = this.hideRejectModal.bind(this);
    this.fetchClinicData = this.fetchClinicData.bind(this);

    this.counter = 0;
  }

  fetchClinicData(sid) {
    let apiUrl = "";
    if (isProduction)
      apiUrl = "https://asia-southeast2-clear-tape-307908.cloudfunctions.net/invitationFetchClinic";
    else{
      if (isMock)
        apiUrl = "http://localhost:5001/clear-tape-307908/us-central1/invitationFetchClinic";
      else
        apiUrl = "https://fb.pmsum.ga/clear-tape-307908/us-central1/invitationFetchClinic";
    }
    axios({
      method: 'post',
      url: apiUrl,
      data: {
        "sid": sid
      },
    })
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          this.clinicData = response.data;
          this.data.creatorName = response.data.creatorName;
          this.data.pTitle = response.data.pTitle;
          this.data.duration = response.data.duration + " minutes";
          var dateObj = new Date(response.data.appointmentTime * 1000);
          // this.data.appointmentTime = moment(dateObj).format('H:mm, D MMM YYYY (ddd)');
          this.data.appointmentTime = moment.tz(dateObj, 'Asia/Kuala_Lumpur').format('D MMM YYYY (ddd), hh:mm A') + ' GMT+8';

          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        // console.log("Error")
        // console.log(error.toString());
        if (error.toString().includes("403") || error.toString().includes("500"))
          this.data.isExpired = true;
        this.setState({ loading: false });
      });
  }

  showSuggestModal = () => {
    this.setState({ showSuggest: true });
  };

  hideSuggestModal = () => {
    this.setState({ showSuggest: false });
  };

  showRejectModal = () => {
    this.setState({ showReject: true });
  };

  hideRejectModal = () => {
    this.setState({ showReject: false });
  };

  componentDidMount() {
    // console.log(this.counter);
    if (this.counter < 1) {
      const parsed = queryString.parse(window.location.search);
      this.fetchClinicData(parsed.sid);
      this.counter++;
    }
  }

  render() {

    if (this.state.loading == true) {
      return (Loading)
    }
    else {
      if (this.data.isExpired)
        return (
          <div>
            {Expired()}
          </div>
        );

      else
        return (
          <div>
            <AppBar />

            <Container>
                {/* <Content creatorName={this.data.creatorName} pTitle={this.data.pTitle} date={this.data.date} duration={this.data.duration} /> */}
                {Content(this.data.creatorName, this.data.pTitle, this.data.appointmentTime, this.data.duration, this.showSuggestModal, this.showRejectModal, this.clinicData)}
                {SuggestDateTimeModal(this.state.showSuggest, this.hideSuggestModal, this.clinicData)}
                {RejectModal(this.state.showReject, this.hideRejectModal, this.clinicData)}
            </Container>
          </div>
        );
    }
  }
}

export default App;


function Content(creatorName, pTitle, date, duration, showSuggestModal, showRejectModal, clinicData) {

  return (
    <div>
      <Row className="justify-content-center text-title">
        <h1>
          You're invited!
  </h1>
      </Row>

      <Row className="justify-content-center text-space text-underline">
        <h5>
          {creatorName}
        </h5>
      </Row>

      <Row className="justify-content-center text-space">
        <p>
          has invited you for an clinic session regarding your proposal
  </p>
      </Row>

      <Row className="justify-content-center text-space text-underline">
        <h5>
          {pTitle}
        </h5>
      </Row>

      <Row className="justify-content-center text-space">
        <Col className="col-1"></Col>
        <Col className="col-1">on</Col>
        <Col className="col-md-5 text-underline"><h5>{date}</h5></Col>
        <Col className="col-1">for</Col>
        <Col className="col-md-2 text-underline"><h5>{duration}</h5></Col>
        <Col className="col-1"></Col>
      </Row>

      <Row className="justify-content-center">
        {/* <Button className="btn-reject col-md-2" onClick={() => {common.acceptOrReject(3, clinicData, "")}}> */}
        <Button className="btn-reject col-md-2" onClick={showRejectModal}>
          REJECT
  </Button>

        <Button className="btn-suggest col-md-3" onClick={showSuggestModal}>
          SUGGEST NEW TIME
  </Button>

        <Button className="btn-accept col-md-3" onClick={() => { common.acceptOrReject(2, clinicData, "") }}>
          ACCEPT INVITATION
  </Button>
      </Row>

    </div>
  );
}

function Expired() {
  return (
    <div className="App-header">
      <VscWarning size={70} />
      <Row className="justify-content-center text-title">
        <h1>
          Sorry, the link has expired.
  </h1>

      </Row>
      <Row className="justify-content-center text-title letter-space">
      <h4>
          Contact PIC if you need further help.
  </h4>
      </Row>
 
    </div>
  );
}


